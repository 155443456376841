import React from 'react';

const About = (props) => {
  return (
      <center>
      <h1>
         **{props.name}**
      </h1>
      <div>
        {/* AF Next 2 lines */}
        <center><h1>Your Video Entertainment Hub </h1> </center>
        <center><h3> A Complete Catalog of YouTube Movies and Songs in one place</h3></center>
      </div>

      <br/>
      <div style={{textAlign:'justify', paddingLeft:'200px', paddingRight:'200px', display:'flex', flexWrap:'wrap'}}>

      <p>
	  At dhoomdb.com, we believe entertainment should be easy, accessible, and enjoyable for everyone. In today’s fast-paced world, finding the time and the right platform to enjoy your favorite movies, shows, and music can be a challenge. That’s where we come in. We’ve created a one-stop streaming portal that combines convenience, variety, and quality, bringing your content to you in an instant.
      </p>

      <p>
Whether you’re a movie buff, a music enthusiast, or someone who loves both, dhoomdb.com offers a seamless, user-friendly experience that puts entertainment at your fingertips. With a wide selection of movies, TV shows, and music from every genre, we make sure you never run out of things to enjoy. Our platform is designed with you in mind, offering easy access across all devices, personalized recommendations, and flexible streaming options.
      </p>

      <p>
No more juggling multiple subscriptions or searching for hours to find the perfect content. With dhoomdb.com you can effortlessly stream the best of both worlds, whenever and wherever you want. Join us today and discover the future of entertainment – made simple, made for you.
      </p>

      <p>
        Launched as of October 2024, this website is still in the process of improvement. Keep checking us as we will be updating our website frequently. Thank you for visiting us!  </p>


      <div>
        {/* AF Next 2 lines */}
        <center><h3>Keep checking us for what is coming up next </h3> </center>
        <center><h4> We are constantly making changes and adding content on our website to improve the user experience </h4></center>
	  <p> We will be supporting more Languages </p>
	  <ul>
	  	<li>Gujarati</li>
	  	<li>Marathi</li>
	  	<li>Bengali</li>
	  	<li>English</li>
	  	<li>Kannada</li>
	  	<li>Malayalam</li>
	  	<li>Punjabi</li>
	  	<li>Bhojpuri</li>
	  	<li>Rajasthani</li>
	  </ul>
	  <p> We will be adding content for more decades </p>
	  <ul>
	  	<li>1990s</li>
	  	<li>1980s</li>
	  	<li>1970s</li>
	  	<li>1960s</li>
	  	<li>1950s</li>
	  	<li>1940s</li>
	  	<li>1930s</li>
	  </ul>
      </div>
      </div>

<br/>
<br/>
<br/>
      </center>

  );
}

export default About;

