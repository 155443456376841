import React, { useState } from 'react';
import {Image } from 'react-native';
import { Link } from 'react-router-dom';

function ActorImageComponent(props) {
  // State to track whether the image is clicked or not
  const [isClicked, setIsClicked] = useState(false);

  // Function to handle the click event
  const handleClick = () => {
    console.log("Inside handleClick");
    setIsClicked(!isClicked);
  };

  return (
    <div>
      {/* Add your image tag with the onClick event */}
      <Image
        alt={props.data_item.actorName}
        title={props.data_item.actorName}
        source={props.data_item.actorPoster}
        onClick={() => { props.onClick(props.data_item); handleClick() }}
        style={props.isClicked ? styles.clickedImage : styles.defaultImage}
      />
      <div style={styles.titleDesc}>
        {props.data_item.actorName}
      </div>
{/* Uncomment if the actor name needs to be a link
      <Link style={styles.titleDesc} to={props.data_item.actorUrl}>
        {props.data_item.actorName}
      </Link>
*/}
    </div>
  );
}

const styles = {
  titleDesc: {
    display: 'block',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    color: 'white',
  },
  defaultImage: {
    margin: 10,
    width: '150px',
    height: '150px',
    border: '1px solid white',
    cursor: 'pointer',
    borderRadius: 100,
  },
  clickedImage: {
    margin: 10,
    width: '150px',
    height: '150px',
    border: '10px solid lightgray',
    cursor: 'pointer',
    borderRadius: 100,
  },
};

export default ActorImageComponent;

