//Copyrights dhoodb.com
//
const NavLabel = (props) => {
	const page = props.page;
	const image = props.image;
	const label = props.lable;
	console.log("page=" + page);
	console.log("image=" + image);
	console.log("label=" + label);

  return (
	((image === undefined) &&
	 (<li style={styles.navItem}><a style={{textDecoration:'auto', color:'white'}} href={props.page}> <h3> {props.lable} </h3> </a>
      </li>)) ||
	((page === undefined) &&
	 (<li style={styles.navItem}><a href={props.pageimg}> <img style={{paddingTop: 10, width:160, height:40}} src={image} alt={label} /> </a></li>))
  );
}

const styles = {
  nav: {
    width: '100%',
    height: 60,
    backgroundColor: 'lightgray',
    padding: '0px',
    position: 'fixed',
  },
  navList: {
    listStyle: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    padding: '0',
  },
  navItem: {
    //AF margin: '0 20px',
    margin: '0 100px',
  },
  li: {
    display: 'block',
    color: '#000',
    padding: '4px 16px',
    textdecoration: 'none',
  },
};

export default NavLabel;

