import React, { useState } from 'react';
import LanguageCard from './LanguageCard';
import MovieImageComponent from './MovieImageComponent';
import config from './config';
import { useMediaQuery } from 'react-responsive';

const Home = (props) => {

  const m_moviesPerPage=20;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  var m_display = 'flex';
  if ( isMobile ) {
    m_display = 'block';
  }

  const [hindiMoviesToShow, setHindMoviesToShow] = useState([]);
  const [teluguMoviesToShow, setTeluguMoviesToShow] = useState([]);
  const [tamilMoviesToShow, setTamilMoviesToShow] = useState([]);

  const fetchMovies = async (m_lang, m_year, m_currentPage) => {

    console.log('Inside Home Page fetchMovies for lang :' + m_lang + ' year :' + m_year);

    let jsonData = [];
    const url = `${config.apiUrl}/movies?year=${m_year}&lang=${m_lang}&page=${m_currentPage}&pageSize=${m_moviesPerPage}`;
                  
    console.log("url in fetchMovies: " + url);
                  
    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchMovies JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = [];
      console.error('Error fetching data:', error);
    }
  }

  useState(() => {
      console.log("useState called in Home");
      const year = 0;
      const page = 1;

      fetchMovies('Hindi', year, page).then((result) => {
            setHindMoviesToShow(result.data);
      });     
      fetchMovies('Telugu', year, page).then((result) => {
            setTeluguMoviesToShow(result.data);
      });     
      fetchMovies('Tamil', year, page).then((result) => {
            setTamilMoviesToShow(result.data);
      });     
  });

  return (
    <div>
	<div>
                <center><h1>Your Video Entertainment Hub </h1></center>
                <center><h3>A Complete Catalog of YouTube Movies and Songs in one place</h3></center>

	</div>

        <div style={{fontFamily: 'Helvetica, sans-serif', fontSize:15}}>
		<div style={{display:m_display, padding:'25px'}}>
		<LanguageCard lang='Hindi' title='Watch Hindi Bollywood Movies and Songs' img='movies/hindi/2020s/2023/5-2023-movie-hindi-pathaan.jpeg' bgcolor='#454545'/>
		<p style={{padding:'5px', border:'1px solid #454545', borderRadius:'25px'}}>
		<h3> Description </h3>

		<b>Your Video Entertainment Hub</b> offers a comprehensive collection of **Bollywood Hindi movies and songs**, all available in one place through YouTube. Whether you're a fan of timeless classics or the latest blockbusters, the hub provides access to a wide array of Hindi films spanning various genres—from romance and drama to action and thriller. In addition to full-length movies, it also features music videos, soundtracks, and iconic songs from Bollywood, offering a complete entertainment experience. With easy navigation, high-quality streaming, and regular updates, this hub serves as your go-to platform for all things Bollywood, available anytime, anywhere.

		<h3> Movies </h3>

		Hindi Bollywood movies are the heart of India’s vibrant film industry, blending captivating storytelling with music, dance, and drama. From timeless classics to modern blockbusters, they cover a wide range of genres including romance, action, comedy, and thriller. Bollywood films often reflect the rich cultural diversity of India.

		<h3> Songs </h3>

		Hindi Bollywood songs are an integral part of India's cinematic experience, blending soulful melodies with powerful lyrics. These songs, ranging from romantic ballads to energetic dance numbers, capture emotions and stories of the films. Often, they become cultural anthems, resonating with audiences and reflecting India's diverse musical heritage.

		</p>
		</div>


		{/* Displaying Hindi Popular Movies  */}

		<h2 style={{margin:'25px'}}> Watch Popular Hindi Movies </h2>
		{hindiMoviesToShow ? (
			<div style={{display:'flex', border:'1px solid #454545', borderRadius:'25px'}}>
			<ul style={{padding:'5px', display:'flex', overflowX:'scroll'}}>
				{hindiMoviesToShow.map((item) =>
					<li key={item.movieId} style={{listStyle:'none'}}>
					<MovieImageComponent data_item={item} />
					</li>
				)}    
			</ul> 
			</div>     
			) :         
			<div/>
		}

		<div style={{display:m_display, padding:'25px'}}>
		<LanguageCard lang='Telugu' title='Watch Telugu Tollywood Movies and Songs' img='movies/telugu/2020s/2023/83-2023-movie-telugu-bhola-shankar.jpeg' bgcolor='#454545'/>
		<p style={{padding:'5px', border:'1px solid #454545', borderRadius:'25px'}}>
		<h3> Description </h3>

		<b> Telugu Tollywood </b> movies are celebrated for their powerful storytelling, emotional depth, and larger-than-life drama. The films often explore intense personal journeys, societal struggles, and human resilience, captivating audiences with relatable characters and gripping narratives. Whether it's an action-packed thriller, a heartfelt family drama, or a passionate romance, Tollywood films excel in creating an emotional impact that resonates deeply with viewers. The performances are marked by raw passion, with actors pouring their heart into every scene, making each moment unforgettable. With captivating music and high-energy sequences, Telugu cinema evokes a wide range of emotions, leaving a lasting impression on its audience.

		<h3> Movies </h3>

		<b> Telugu Tollywood </b> movies are a cornerstone of South Indian cinema, known for their compelling narratives, vibrant action, and rich emotional depth. From intense dramas to high-octane thrillers, Tollywood films cover a variety of genres. These movies often feature stunning visuals, gripping performances, and catchy music, making them a global favorite.


		<h3> Songs </h3>

		<b> Telugu Tollywood </b> songs are an essential part of the cinematic experience, blending melody, rhythm, and emotion. From soulful ballads to peppy dance numbers, these songs capture the essence of the film’s story and characters. With powerful lyrics and captivating music, they evoke deep emotions and leave a lasting impression.

		</p>
		</div>

		{/* Displaying Hindi Popular Movies  */}

		<h2 style={{margin:'25px'}}> Watch Popular Telugu Movies </h2>
		{teluguMoviesToShow ? (
			<div style={{display:'flex', border:'1px solid #454545', borderRadius:'25px'}}>
			<ul style={{padding:'5px', display:'flex', overflowX:'scroll'}}>
				{teluguMoviesToShow.map((item) =>
					<li key={item.movieId} style={{listStyle:'none'}}>
					<MovieImageComponent data_item={item} />
					</li>
				)}    
			</ul> 
			</div>     
			) :         
			<div/>
		}

		<div style={{display:m_display, padding:'25px'}}>
		<LanguageCard lang='Tamil' title='Watch Tamil Kollywood Movies and Songs' img='movies/tamil/2020s/2023/199-2023-movie-tamil-leo.jpeg' bgcolor='#454545'/>
		<p style={{padding:'5px', border:'1px solid #454545', borderRadius:'25px'}}>
		<h3> Description </h3>

		<b> Tamil Kollywood </b> movies are renowned for their unique blend of powerful storytelling, dynamic action, and emotional depth. Known for their bold and diverse themes, Kollywood films often tackle social issues, personal struggles, and cultural narratives, creating a strong emotional connection with the audience. Whether it's an intense drama, a high-octane action film, or a heartwarming romance, Tamil cinema resonates with authenticity and passion. The performances are marked by gripping intensity, while the music and dance sequences elevate the cinematic experience. With a rich tradition of blending artistic expression with mass appeal, Kollywood movies continue to captivate audiences worldwide.


		<h3> Movies </h3>

		<b> Tamil Kollywood </b> movies are a major force in Indian cinema, renowned for their compelling storytelling, powerful performances, and striking visuals. Spanning genres from action and drama to romance and thriller, Kollywood films often blend emotional depth with mass appeal. Known for their strong music and unique cultural elements, these movies have a global fanbase.

		<h3> Songs </h3>

		<b> Tamil Kollywood </b> movies are known for their powerful storytelling, emotional depth, and dynamic performances. They often explore diverse themes like love, family, action, and social issues, captivating audiences with compelling narratives. With striking visuals, memorable music, and intense drama, Kollywood films offer a unique cinematic experience that resonates deeply.

		</p>
		</div>

		{/* Displaying Hindi Popular Movies  */}

		<h2 style={{margin:'25px'}}> Watch Popular Tamil Movies </h2>
		{tamilMoviesToShow ? (
			<div style={{display:'flex', border:'1px solid #454545', borderRadius:'25px'}}>
			<ul style={{padding:'5px', display:'flex', overflowX:'scroll'}}>
				{tamilMoviesToShow.map((item) =>
					<li key={item.movieId} style={{listStyle:'none'}}>
					<MovieImageComponent data_item={item} />
					</li>
				)}    
			</ul> 
			</div>     
			) :         
			<div/>
		}

{/*
	            <LanguageCard lang='Marathi' title='Watch Marathi Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='Bengali' title='Watch Bengal Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='English' title='Watch English Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='Kannada' title='Watch Kannada Movies and Songs' img='' bgcolor='#454545' movie_lang='hindimovies'/>
	            <LanguageCard lang='Malayalam' title='Watch Malayalam Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='Punjabi' title='Watch Punjabi Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='Bhojpuri' title='Watch Bhojpuri Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='Gujarati' title='Watch Gujarati Movies and Songs' img='' bgcolor='#454545'/>
	            <LanguageCard lang='Rajasthani' title='Watch Rajasthani Movies and Songs' img='' bgcolor='#454545'/>
*/}

        </div>

    </div>
  );
}

export default Home;

